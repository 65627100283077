'use strict'

// get form elements
const featureRequestButton = document.getElementById('feature-request-submit');
const featureRequestForm = document.getElementById('feature-request-form');
const featureRequestModalEl = document.getElementById('feature-request-modal');

const rateExperienceButton = document.getElementById('rate-experience-submit');
const rateExperienceForm = document.getElementById('rate-experience-form');
const rateExperienceModalEl = document.getElementById('rate-experience-modal');

const confirmationModalEl = document.getElementById('feedback-confirmation-modal')

// Function to hide a target modal
function hideTargetModal(targetModal) {
    targetModal.classList.add('tw-hidden');
    targetModal.classList.remove('tw-flex');
    targetModal.setAttribute('aria-hidden', 'true');
    targetModal.removeAttribute('aria-modal');
    targetModal.removeAttribute('role');
    document.body.classList.remove('tw-overflow-hidden');
}

// Function to show the confirmation modal
function showConfirmationModal() {
    confirmationModalEl.classList.add('tw-flex');
    confirmationModalEl.classList.remove('tw-hidden');
    confirmationModalEl.setAttribute('aria-modal', 'true');
    confirmationModalEl.setAttribute('role', 'dialog');
    confirmationModalEl.removeAttribute('aria-hidden');
    document.body.classList.add('tw-overflow-hidden');
}

// Function to hide the confirmation modal
function hideConfirmationModal() {
    location.reload();
}

featureRequestButton.addEventListener('click', () => {
    // Remove all error messages
    document.getElementById('name_error').classList.add('tw-hidden');
    document.getElementById('feature_email_error').classList.add('tw-hidden');
    document.getElementById('feature_required_error').classList.add('tw-hidden');

    if (document.getElementById('id_name').value === '') {
        document.getElementById('name_error').classList.remove('tw-hidden');
        return
    }

    if (document.getElementById('id_feature_email').value === '') {
        document.getElementById('feature_email_error').classList.remove('tw-hidden');
        return
    }

    if (document.getElementById('id_feature_required').value === '') {
        document.getElementById('feature_required_error').classList.remove('tw-hidden');
        return
    }

    if (!featureRequestForm.checkValidity()) {
        document.getElementById('feature_email_error').classList.remove('tw-hidden');
        return
    }

    // submit the form
    featureRequestForm.submit();

    // reset the form
    featureRequestForm.reset();

    // hide featureRequest modal
    hideTargetModal(featureRequestModalEl);

    // show confirmation modal
    showConfirmationModal();

});

rateExperienceButton.addEventListener('click', () => {
    // Remove all error messages
    document.getElementById('rating_error').classList.add('tw-hidden');
    document.getElementById('rating_name_error').classList.add('tw-hidden');
    document.getElementById('rating_email_error').classList.add('tw-hidden');

    if (!document.querySelector('input[name="rating"]:checked')) {
        document.getElementById('rating_error').classList.remove('tw-hidden');
        return
    }

    if (document.getElementById('id_rating_name').value === '') {
        document.getElementById('rating_name_error').classList.remove('tw-hidden');
        return
    }

    if (document.getElementById('id_email').value === '') {
        document.getElementById('rating_email_error').classList.remove('tw-hidden');
        return
    }

    if (!rateExperienceForm.checkValidity()) {
        document.getElementById('rating_email_error').classList.remove('tw-hidden');
        return
    }

    // submit the form
    rateExperienceForm.submit();

    // reset the form
    rateExperienceForm.reset();

    // hide featureRequest modal
    hideTargetModal(rateExperienceModalEl);

    // show confirmation modal
    showConfirmationModal();

});

// reload the page to close the modal and reset event listeners and modal backdrops
document.getElementById('feedback-confirmation-close').addEventListener('click', () => {
    hideConfirmationModal();
});